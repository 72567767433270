<template>
  <div id="product">
    <el-dialog
      :title="airFormTitle"
      width="1200px"
      :visible.sync="airDialogVisible"
      :close-on-click-modal="false"
      @close="airDialogClose"
      ><el-form
        ref="airFormRef"
        :inline="true"
        :model="airForm"
        :rules="productFormRules2"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="设备型号" prop="deviceType">
          <el-input
            v-model="airForm.deviceType"
            placeholder="请输入设备型号"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="设备编号" prop="deviceNo">
          <el-input
            v-model="airForm.deviceNo"
            placeholder="请输入设备编号"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="运行时间" prop="offTime">
          <el-date-picker
            v-model="runningTime"
            :readonly="handleType !== 'add' && handleType !== 'update'"
            type="datetimerange"
            value-format="yyyy-MM-dd hh:mm:ss"
            range-separator="至"
            start-placeholder="开机时间"
            end-placeholder="关机时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="airDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="productFormSubmit"> 确 定 </el-button>
      </div>
      <section v-if="handleType != 'add'">
        <el-button
          type="primary"
          @click="handleAdd2"
          v-if="handleType === 'update'"
        >
          新 增
        </el-button>
        <el-table :data="airForm.airDetailList" style="width: 100%">
          <el-table-column prop="time" label="时间" align="center" />
          <el-table-column label="臭氧消毒" align="center">
            <el-table-column
              prop="startTime"
              label="消毒开始时间"
              align="center"
            />
            <el-table-column
              prop="endTime"
              label="消毒关闭时间"
              align="center"
            />
          </el-table-column>
          <el-table-column
            prop="selfCleanTime"
            label="自净时间"
            align="center"
          />
          <el-table-column
            prop="initialPressure"
            label="初效压差/Pa"
            align="center"
          />
          <el-table-column
            prop="middlePressure"
            label="中效压差/Pa"
            align="center"
          />
          <el-table-column prop="checkResult" label="检查结果" align="center" />
          <el-table-column prop="recorder" label="记录人" align="center" />
          <el-table-column prop="remarks" label="备注" align="center" />
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                icon="el-icon-delete"
                size="small"
                @click.stop="handleDelete2(scope.$index, airForm.airDetailList)"
                v-if="handleType === 'update'"
              >
                删除
              </el-button>
              <el-button
                type="text"
                icon="el-icon-edit"
                size="small"
                @click.stop="handleUpdate2(scope.$index, scope.row)"
                v-if="handleType === 'update'"
              >
                修改
              </el-button>
              <el-button
                type="text"
                icon="el-icon-info"
                size="small"
                @click.stop="handleInfo2(scope.$index, scope.row)"
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div>
          每班每隔3h记录一次运行参数，“检查结果”中记录正常、或超过纠偏限应及时清洗或更换、或超过警戒限应立即清洗或更换。
        </div>
        <!-- <div slot="footer">
          <el-button @click="airDialogVisible = false">关闭</el-button>
        </div> -->
      </section>
      <el-dialog
        width="60%"
        title="新增操作记录"
        :visible.sync="innerVisible"
        :close-on-click-modal="false"
        append-to-body
      >
        <el-form
          ref="productFormRef"
          :model="airDetailForm"
          :rules="productFormRules"
          label-position="right"
          label-width="120px"
        >
          <el-form-item label="时间" prop="time">
            <el-date-picker
              v-model="airDetailForm.time"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd hh:mm:ss"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="消毒开始时间" prop="startTime">
            <el-date-picker
              v-model="airDetailForm.startTime"
              type="datetime"
              placeholder="消毒开始时间"
              value-format="yyyy-MM-dd hh:mm:ss"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="消毒结束时间" prop="endTime">
            <el-date-picker
              v-model="airDetailForm.endTime"
              type="datetime"
              placeholder="消毒结束时间"
              value-format="yyyy-MM-dd hh:mm:ss"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="自净时间" prop="selfCleanTime">
            <el-date-picker
              v-model="airDetailForm.selfCleanTime"
              type="datetime"
              placeholder="自净时间"
              value-format="yyyy-MM-dd hh:mm:ss"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="初效压差" prop="initialPressure">
            <el-input
              v-model="airDetailForm.initialPressure"
              placeholder="请输入初效压差"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="中效压差" prop="middlePressure">
            <el-input
              v-model="airDetailForm.middlePressure"
              placeholder="请输入中效压差"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="检查结果" prop="checkResult">
            <el-input
              v-model="airDetailForm.checkResult"
              placeholder="请输入检查结果"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input
              v-model="airDetailForm.remarks"
              placeholder="请输入备注"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="innerVisible = false"> 取 消 </el-button>
          <el-button type="primary" @click="productFormSubmit2">
            确 定
          </el-button>
        </div>
      </el-dialog>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备名称">
        <el-input
          v-model="searchForm.deviceType"
          placeholder="请输入设备名称"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="airList.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="deviceType" label="设备型号" />
      <el-table-column prop="deviceNo" label="设备编号" />
      <el-table-column label="类型" prop="deviceType"></el-table-column>
      <el-table-column prop="unit" label="日期" />
      <el-table-column prop="startupTime" label="开机时间" />
      <el-table-column prop="offTime" label="关机时间" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="airList.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  getAirList,
  addAirList,
  deleteAirList,
  getInfoDetail,
  updateDetail,
  addDetail,
} from "@/api/produce/produce.js";

export default {
  data() {
    return {
      airDialogVisible: false,
      innerVisible: false,
      airFormTitle: "",
      airForm: {
        deviceType: "",
        deviceNo: "",
        startupTime: "",
        offTime: "",
      },
      productPage: {
        list: [],
        total: 0,
      },
      airList: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceType: "",
      },
      typeList: [],
      typeMap: [],
      tableData: [],
      handleType: "",
      handleDetailType: "",
      runningTime: [],
      airDetailForm: {},
      productFormRules: {
        time: [
          {
            required: true,
            message: "时间名称不能为空",
            trigger: ["blur", "change"],
          },
        ],
        startTime: [
          {
            required: true,
            message: "消毒开始时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
        endTime: [
          {
            required: true,
            message: "消毒结束时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
        selfCleanTime: [
          {
            required: true,
            message: "自净时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
        initialPressure: [
          {
            required: true,
            message: "初效压差不能为空",
            trigger: ["blur", "change"],
          },
        ],
        middlePressure: [
          {
            required: true,
            message: "中效压差不能为空",
            trigger: ["blur", "change"],
          },
        ],
        checkResult: [
          {
            required: true,
            message: "检查结果不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      productFormRules2: {
        deviceType: [
          {
            required: true,
            message: "设备型号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        deviceNo: [
          {
            required: true,
            message: "设备编号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        offTime: [
          {
            required: true,
            message: "运行时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  async created() {
    let params = {
      pageNum: 1,
      pageSize: 10,
    };
    let airList = await getAirList(params);
    this.airList = airList;
  },
  methods: {
    airDialogClose() {
      this.$refs.airFormRef.resetFields();
    },
    productFormSubmit() {
      this.$refs.airFormRef.validate(async (valid) => {
        if (valid) {
          if (this.handleType === "add") {
            console.log(this.airForm, "this.airForm");
            await addAirList(this.airForm);
          } else if (this.handleType === "update") {
            let params = this.airForm;
            params.airDetailJson = JSON.stringify(params.airDetailList);
            delete params.airDetailList;
            await updateDetail(params);
          }
          this.airList = await getAirList(this.searchForm);
          this.airDialogVisible = false;
        }
      });
    },
    productFormSubmit2() {
      this.$refs.productFormRef.validate(async (valid) => {
        this.airDetailForm.airId = this.airForm.id;
        if (valid) {
          if (this.handleDetailType === "add") {
            let data = await addDetail(this.airDetailForm);
            let list = this.airForm.airDetailList;
            list.push(data);
            this.airForm.airDetailList = list;
          } else if (this.handleDetailType === "update") {
            let list = this.airForm.airDetailList;
            console.log(this.airDetailForm, "this.airDetailForm");
            delete this.airDetailForm.index;
            delete this.airDetailForm.inputType;
            list.splice(this.airDetailForm.index, 1, this.airDetailForm);
            this.airForm.airDetailList = list;
          }
          this.innerVisible = false;
        }
      });
    },
    handleAdd() {
      this.airFormTitle = "新增空调操作记录";
      this.handleType = "add";
      this.airDialogVisible = true;
    },
    handleAdd2() {
      this.handleDetailType = "add";
      this.innerVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteAirList(row.id);
        if (this.productPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--;
        }
        this.airList = await getAirList(this.searchForm);
      });
    },
    handleDelete2(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        row.splice(index, 1);
      });
    },
    handleUpdate(index, row) {
      this.airFormTitle = "净化空调系统日常运行操作记录";
      this.handleType = "update";
      getInfoDetail(row.id).then((res) => {
        console.log(res);
        this.airForm.id = res.id;
        this.airForm.deviceNo = res.deviceNo;
        this.airForm.deviceType = res.deviceType;
        this.airForm.offTime = res.offTime;
        this.airForm.startupTime = res.startupTime;
        this.airForm.airDetailList = res.airDetailList;
        this.runningTime = [res.startupTime || "", res.offTime || ""];
        this.airDialogVisible = true;
      });
    },
    handleInfo(index, row) {
      this.airFormTitle = "净化空调系统日常运行操作记录";
      this.handleType = "detail";
      getInfoDetail(row.id).then((res) => {
        this.airForm.id = res.id;
        this.airForm.deviceNo = res.deviceNo;
        this.airForm.deviceType = res.deviceType;
        this.airForm.offTime = res.offTime;
        this.airForm.startupTime = res.startupTime;
        this.airForm.airDetailList = res.airDetailList;
        this.runningTime = [res.startupTime || "", res.offTime || ""];
        this.airDialogVisible = true;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      getAirList(this.searchForm).then((res) => {
        console.log(res, "res");
        this.airList = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      getAirList(this.searchForm).then((res) => {
        this.airList = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      getAirList(this.searchForm).then((res) => {
        this.airList = res;
      });
    },
    handleInfo2(index, row) {
      this.handleDetailType = "detail";
      let airDetailForm = this.airDetailForm;
      airDetailForm = { ...row };
      airDetailForm.inputType = "detail";
      this.airDetailForm = airDetailForm;
      this.innerVisible = true;
    },
    handleUpdate2(index, row) {
      this.handleDetailType = "update";
      let airDetailForm = this.airDetailForm;
      airDetailForm = { ...row };
      airDetailForm.index = index;
      airDetailForm.inputType = "update";
      this.airDetailForm = airDetailForm;
      this.innerVisible = true;
    },
  },

  watch: {
    runningTime(val, oldVal) {
      this.airForm.startupTime = val ? val[0] : "";
      this.airForm.offTime = val ? val[1] : "";
    },
    airDialogVisible(val) {
      if (!val) {
        this.airForm = {};
        this.runningTime = [];
      }
    },
  },
};
</script>

<style>
#product section {
  margin: 0px 0px 20px 20px;
}
</style>
